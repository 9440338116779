import React from "react"

import { Link } from "gatsby"

import { BackgroundImage } from "../../components/BackgroundImage"

import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"

import "./index.css"

const SolutionsIndexPage = () => (
  <HomePageLayout pageTitle="Solutions">
    <SEO title="Solutions" />
    <div className="hero solutions-hero">
      <div className="hero-background">
        <BackgroundImage imageName="solutions.jpg" />
      </div>
      <div className="hero-content">
        <div className="container">
          <div className="solution-categories">
            <Link
              to="/solutions/products"
              className="solution-category-link js-focus-visible"
            >
              <h1 className="solution-category-title">Products</h1>

              <p className="solution-category-description">
                A group of in-house-products we sell to clients
              </p>
            </Link>
            <Link
              to="/solutions/projects"
              className="solution-category-link js-focus-visible"
            >
              <h1 className="solution-category-title">Projects</h1>

              <p className="solution-category-description">
                A group of technology solutions developed for clients based on
                demands
              </p>
            </Link>
            {/*<Link*/}
            {/*  to="/solutions/websites"*/}
            {/*  className="solution-category-link js-focus-visible"*/}
            {/*>*/}
            {/*  <h1 className="solution-category-title">Websites</h1>*/}

            {/*  <p className="solution-category-description">*/}
            {/*    A collection of websites in which we have worked on*/}
            {/*  </p>*/}
            {/*</Link>*/}
          </div>
        </div>
      </div>
    </div>
  </HomePageLayout>
)

export default SolutionsIndexPage
